import { useGetFeaturedContentQuery } from "services/graphql";

export function useGetFeaturedPlaylists() {
  const { data, loading, error } = useGetFeaturedContentQuery();
  const isLoading = loading || !data?.featuredPlaylists;
  const featuredPlaylists = data?.featuredPlaylists;

  return {
    featuredPlaylists,
    loading: isLoading,
    error,
  };
}
