/* eslint-disable camelcase */
import React from "react";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { SkeletonText, SkeletonTextLoader } from "app/components/SkeletonText";
import { SkeletonDivAnimation } from "app/components/SkeletonAnimation";

const SKELETON_WIDTH = "360px";
const SKELETON_HEIGHT = "214px";
const SKELETON_THUMBNAIL_HEIGHT = "168px";

export const PlaylistCardSkeleton = () => {
  return (
    <Flex
      width={SKELETON_WIDTH}
      height={SKELETON_HEIGHT}
      flexDirection="column"
    >
      <Flex
        width="100%"
        height={SKELETON_THUMBNAIL_HEIGHT}
        backgroundColor="monochrome.11"
        borderRadius="8px"
        overflow="hidden"
        position="relative"
        zIndex="-1"
      >
        <Div zIndex="1" position="absolute" width="100%" height="100%">
          <SkeletonDivAnimation />
        </Div>
        <Flex
          width="100%"
          height="100%"
          flexDirection="column"
          justifyContent="flex-end"
          alignContent="flex-end"
          padding="18px 10px"
          overflow="hidden"
          position="absolute"
          zIndex="2"
        >
          <SkeletonText height="16px" width="166px">
            <SkeletonTextLoader
              width={SKELETON_WIDTH}
              height={SKELETON_HEIGHT}
            />
          </SkeletonText>
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        padding="10px 10px 0 10px"
        overflow="hidden"
        position="relative"
        zIndex="2"
      >
        <Flex>
          <SkeletonText height="16px" width="55px" mb="8px" mr="8px">
            <SkeletonTextLoader
              width={SKELETON_WIDTH}
              height={SKELETON_HEIGHT}
            />
          </SkeletonText>
          <SkeletonText height="16px" width="55px" mr="8px">
            <SkeletonTextLoader
              width={SKELETON_WIDTH}
              height={SKELETON_HEIGHT}
            />
          </SkeletonText>
          <SkeletonText height="16px" width="55px">
            <SkeletonTextLoader
              width={SKELETON_WIDTH}
              height={SKELETON_HEIGHT}
            />
          </SkeletonText>
        </Flex>
        <Flex>
          <SkeletonText height="12px" width="82px" mr="16px">
            <SkeletonTextLoader
              width={SKELETON_WIDTH}
              height={SKELETON_HEIGHT}
            />
          </SkeletonText>
          <SkeletonText height="12px" width="82px">
            <SkeletonTextLoader
              width={SKELETON_WIDTH}
              height={SKELETON_HEIGHT}
            />
          </SkeletonText>
        </Flex>
      </Flex>
    </Flex>
  );
};
