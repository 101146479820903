import { Slider } from "app/components/Slider";
import React from "react";
import { HomeSelectContentContainer } from "services/typewriter/segment";
import { PlaylistCardSkeleton } from "app/components/PlaylistCard/PlaylistCardSkeleton";
import { PlaylistCard } from "app/components/PlaylistCard";
import { Section } from "../Section";
import { useGetFeaturedPlaylists } from "./hooks";

export const FeaturedPlaylists = () => {
  const { loading, featuredPlaylists } = useGetFeaturedPlaylists();
  const loadingStateComponent = () => {
    return (
      <Slider
        data={[{}, {}, {}, {}, {}]}
        slideComponent={PlaylistCardSkeleton}
        arrowTopPosition="35%"
        slidesSpacing={12}
        cardHeight="186px"
      />
    );
  };

  if (!loading && !featuredPlaylists.length) {
    return null;
  }

  return (
    <Section
      title="Featured Playlists"
      showLoadingState={loading}
      showTitleLoadingState
      loadingStateComponent={loadingStateComponent()}
    >
      <Slider
        data={featuredPlaylists}
        slideComponent={(props: any) => (
          <PlaylistCard
            {...props}
            playlistData={props.data}
            container={HomeSelectContentContainer.FeaturedContent}
            selectedFrom="FeaturedPlaylists"
          />
        )}
        slidesSpacing={24}
        cardHeight="190px"
      />
    </Section>
  );
};
